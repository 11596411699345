import React from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import { History } from 'history'
import { CssBaseline, Container } from '@material-ui/core'

import WaAppBar from './WaAppBar'
import ListMembers from './members/ListMembers'
import MemberProfile from './members/MemberProfile'

interface AppProps {
  history: History;
}

const App = ({ history }: AppProps) => {
  return (
    <Router history={history}>
      <CssBaseline />
      <WaAppBar />
      <Container maxWidth="lg">
        <Switch>
          <Route path="/member" component={MemberProfile} />
          <Route path="/" component={ListMembers} />
        </Switch>
      </Container>
    </Router>
  );
}

export default App;
