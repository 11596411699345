import React from 'react';
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Typography, Box, Paper } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const styles = (theme: Theme) => createStyles({
  table: {
    // minWidth: 250,
  },
  title: {
    marginTop: 35,
    marginBottom: 15,
  },
  paper: {
    padding: 15
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 170,
    },
  },
});


type BillingHistoryState = {
  billingEntries: Array<BillingEntry>
}

type BillingEntry = {
  amount: number,
  title: string,
  billingDate: Date
}

class BillingHistory extends React.Component<WithStyles<typeof styles>, BillingHistoryState> {

  constructor(props: WithStyles<typeof styles>) {
    super(props)
    let entries: Array<BillingEntry> = [
      { amount: 67.40, title: 'Day Passes', billingDate: new Date('2014/6/17') },
      { amount: 18.60, title: 'Room Booking', billingDate: new Date('2016/8/23') },
    ]
    this.state = { billingEntries: entries }
  }

  handleClick(event: React.MouseEvent<unknown>, name: string) {
  }

  render() {
    return (
      <TableContainer className={this.props.classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Invoice</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.billingEntries.map(row => (
              <TableRow key={row.title}
                hover
                onClick={event => this.handleClick(event, row.title)}>
                <TableCell component="th" scope="row">
                  {row.title}<br />{row.billingDate.toDateString()}
                </TableCell>
                <TableCell align="right">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withStyles(styles)(BillingHistory)