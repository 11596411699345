import React from 'react'
import { Redirect } from 'react-router-dom'
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import firebase from 'firebase'
import { Typography, Box } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({
  table: {
    minWidth: 650,
  },
  title: {
    marginTop: 35,
    marginBottom: 15,
  }
})

type Member = {
  name: string, 
  email: string, 
  membership: string, 
  joined: Date
}

type ListMembersState = {
  selectedName: String,
  members: Array<Member>
}

const db = firebase.firestore()    

class ListMembers extends React.Component<WithStyles<typeof styles>, ListMembersState> {

  constructor(props: WithStyles<typeof styles>) {
    super(props)

    let rows: Array<Member> = []
    //   {name: 'Steve', email: 'steve@theworkaround.ca', membership: 'Community', joined: new Date('2018/1/1')},
    //   {name: 'Amanda', email: 'amanda@theworkaround.ca', membership: 'Admin', joined: new Date('2018/1/1')},
    //   {name: 'Renee', email: 'renee@theworkaround.ca', membership: 'Monthly', joined: new Date('2019/6/5')},
    //   {name: 'Chris', email: 'chris@theworkaround.ca', membership: 'Community', joined: new Date('2020/1/20')},
    //   {name: 'Melissa', email: 'melissa@theworkaround.ca', membership: 'Community', joined: new Date('2019/9/11')},
    // ]

    this.state = {selectedName: '', members: rows }
  }

  componentDidMount() {
    db.collection("members").get().then(querySnapshot => {
      const membersCollection = querySnapshot.docs.map<Member>( doc => {
        return {
          name: doc.get('first_name'), 
          email: doc.get('email'), 
          membership: doc.get('membership'), 
          joined: doc.get('joined') }
        })
        this.setState({members: membersCollection})
      })
  }

  handleClick(event: React.MouseEvent<unknown>, name: string) {
    this.setState({selectedName: name})
  }

  render() {
    if (this.state.selectedName !== null && this.state.selectedName !== '')
      return (<Redirect to={`/member?name=${this.state.selectedName}`}  />)
    else
      return (
        <Box>
          <Typography className={this.props.classes.title} variant="h4">Member Directory</Typography>
          <TableContainer className={this.props.classes.table} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="right">Membership</TableCell>
                  <TableCell align="right">Joined</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.members.map(row => (
                  <TableRow key={row.name}
                    hover
                    onClick={event => this.handleClick(event, row.name)}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="right">{row.membership}</TableCell>
                    <TableCell align="right">{row.joined.toDateString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )
  }
}

export default withStyles(styles)(ListMembers)