import React from 'react'
import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Typography, Box, Grid, Paper, TextField, Button } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import BillingHistory from './BillingHistory'

const styles = (theme: Theme) => createStyles({
  table: {
    minWidth: 650,
  },
  title: {
    marginTop: 35,
    marginBottom: 15,
  },
  subtitle: {
    marginTop: 10,
    marginBottom: 10,
  },
  paper: {
    padding: 15
  },
  actionButton: {
    marginTop: 10,
    marginRight: 10,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 170,
    },
  },
});


type MemberProfileState = {
  children: Array<Child>
}

type Child = {
  firstName: String,
  lastName: String,
  dateOfBirth: Date
}

class MemberProfile extends React.Component<WithStyles<typeof styles>, MemberProfileState> {


  constructor(props: WithStyles<typeof styles>) {
    super(props)
    let deiz_children: Array<Child> = [
      { firstName: 'Fiona', lastName: 'Munday', dateOfBirth: new Date('2014/6/17') },
      { firstName: 'Everett', lastName: 'Munday', dateOfBirth: new Date('2016/8/23') },
    ]
    this.state = { children: deiz_children }

     // This binding is necessary to make `this` work in the callback
     this.addChildRow = this.addChildRow.bind(this);
  }

  handleDateChange(date: (Date | null)) {

  }

  addChildRow() {
    let children = this.state.children
    children.push({ firstName: '', lastName: '', dateOfBirth: new Date()})
    this.setState({children})
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Typography className={this.props.classes.title} variant="h4">Member Profile</Typography>
        <form>
          <Box className={this.props.classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Typography className={this.props.classes.subtitle} variant="h5">Profile Details</Typography>
                <Paper className={this.props.classes.paper}>
                  <TextField id="standard-basic" label="First Name" />
                  <TextField id="standard-basic" label="Last Name" />
                  <TextField id="standard-basic" label="Email" />
                  <TextField id="standard-basic" label="Phone" />
                </Paper>
                <Typography className={this.props.classes.subtitle} variant="h5">Children</Typography>
                <Paper className={this.props.classes.paper}>
                  {this.state.children.map(row => (
                    <Box>
                      <TextField id="standard-basic" label="Child First Name" value={row.firstName} />
                      <TextField id="standard-basic" label="Child Last Name" value={row.lastName} />
                      <KeyboardDatePicker id="date-picker-inline"
                        disableToolbar
                        variant="inline"
                        label="Child Date of Birth"
                        format="MM/dd/yyyy"
                        value={row.dateOfBirth}
                        onChange={this.handleDateChange}
                      />
                    </Box>
                  ))}
                  <Button className={this.props.classes.actionButton} variant="contained" color="default" 
                    onClick={this.addChildRow}>Add Row</Button>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Typography className={this.props.classes.subtitle} variant="h5">Billing History</Typography>
                <BillingHistory />
              </Grid>
            </Grid>
            <Button className={this.props.classes.actionButton} variant="contained" color="primary">Save Changes</Button>
            <Button className={this.props.classes.actionButton} variant="contained" color="default">Cancel</Button>
          </Box>
        </form>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(MemberProfile)